import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSubscriptionStatus } from '../utils/apis/getSubscriptionStatus';
import Header from '../components/Header';
import Footer from '../components/Footer';
import * as amplitude from "@amplitude/analytics-browser";
import './PaymentStatus.css'; // Import the new CSS file

const PaymentStatus = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState('checking');
    const maxAttempts = 10;
    const intervalTime = 10000;

    useEffect(() => {
        let attempts = 0;

        const checkStatus = async () => {
            if (attempts < maxAttempts) {
                const result = await getSubscriptionStatus(id);
                if (result.success) {
                    if (result.data.status === 'active') {
                        setStatus('active');
                        toast.success('Payment successful!');
                        amplitude.track('Payment Check Result Success', {
                            subscriptionId: id
                        });
                        setTimeout(() => {
                            navigate('/home');
                        }, 2000);
                        return;
                    }
                } else {
                    toast.error('Failed to check payment status');
                    amplitude.track('Payment Check Failure', {
                        subscriptionId: id
                    });
                }
                attempts += 1;
                setTimeout(checkStatus, intervalTime);
            } else {
                setStatus('failed');
                toast.error('Payment failed');
                amplitude.track('Payment Check Result Failure', {
                    subscriptionId: id
                });
            }
        };

        checkStatus();

        return () => {
            clearTimeout(checkStatus);
        };
    }, [id, navigate]);

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex flex-col items-center justify-center p-4">
                {status === 'checking' && (
                    <>
                        <div className="juggling-container mb-4">
                            <div className="ball ball-1"></div>
                            <div className="ball ball-2"></div>
                            <div className="ball ball-3"></div>
                        </div>
                        <p className="text-lg text-black">do not refresh this page. checking payment status, this can take upto 2 minutes...</p>
                    </>
                )}
                {status === 'failed' && (
                    <>
                        <p className="text-lg text-red-500">Payment failed. Please try again.</p>
                        <button className="btn mt-4" onClick={() => navigate('/plans')}>Retry</button>
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default PaymentStatus;